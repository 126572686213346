import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes, css } from "styled-components"; 
import { getAllspecialites, getAllStoresActive} from '../../../shared/slice/restaurant/RestaurantService';
import {ImageURI, store, TestMode } from '../../../shared';
import { setOrder } from '../../../shared/slice/order/OrderSlice';
import { resetPromo } from '../../../shared/slice/promos/PromosSlice';
import { useTranslation } from 'react-i18next';
import { setModesModal } from '../../../shared/slice/ModalMode/ModalMode';
import AddressInput from '../../../components/AddressInput/AddressInput';
import { setScroll } from '../../../shared/slice/scroll/ScrollSlice';


const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371;
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
export default function StoreBySpeciality() {
 
  const { t } = useTranslation();
  const [coordinates, setCoordinates] = useState({ lat: null, lon: null });
  const [distances, setDistances] = useState({});
  const [location, setLocation] = useState(null);
  const navigate = useNavigate();
  const [specialite1, setspecialite] = useState([]);
  const [AllStores, setAsllStores] = useState([]);
  const [FilteredRestaurants, setFilteredRestaurants] = useState([]);
  // const [TpStores, setTpStores] = useState([]);
  // const [TpProduct, setTpProduct] = useState();

  const [idSpecialite, setIdSpecialite] = useState([]);
  const [inputValue, setinputValue] = useState(false);


  const inputValue2 = (inputValue2) => {
  if (inputValue2===""){setinputValue(false)}
  else{setinputValue(true)}
  };
  useEffect(() => {
    if(inputValue===false) {





      setFilteredRestaurants(AllStores);
      setIdSpecialite([])
      setLocation(null)
     
      setCoordinates({ lat: null, lon: null })
    
      setDistances({})
    }
  }, [inputValue]);


  const onSelectLocation = (selectedLocation) => {
    if (selectedLocation) {
      setCoordinates({
        lat: selectedLocation.value.lat,
        lon: selectedLocation.value.lon,
      });
      setLocation(selectedLocation); // Set the location for the useEffect
    }
  };



  const calculateDistanceFromInput = (location) => {
    if (location) {
      const distancesMap = {};
      
      // Calculate distances and populate the distancesMap
      FilteredRestaurants.forEach((store) => {
        const distance = calculateDistance(
          location.value.lat,
          location.value.lon,
          store.latitude,
          store.longitude
        ).toFixed(2);
        distancesMap[store._id] = distance;
      });
  
      // Set distances to state
      setDistances(distancesMap);
  
      // Filter stores based on distance condition
      const filteredStoresWithDistance = FilteredRestaurants.filter(store => 
        !distancesMap[store._id] || distancesMap[store._id] <= 60
      );
  
      // Sort the filtered stores
      const sortedStores = filteredStoresWithDistance.map((store) => ({
        ...store,
        distance: calculateDistance(
          store.latitude,
          store.longitude,
          coordinates.lat,
          coordinates.lon
        ),
      })).sort((a, b) => a.distance - b.distance);
  
      // Update the state with sorted stores
      setFilteredRestaurants(sortedStores);
    }
  };
  
 


  const calculateDistanceFromInput2 = (location, res) => {
    if (location) {
      const distancesMap = {};
  
      // Calculate distances and populate distancesMap
      res.forEach((store) => {
        const distance = calculateDistance(
          location.value.lat,
          location.value.lon,
          store.latitude,
          store.longitude
        ).toFixed(2);
        distancesMap[store._id] = distance;
      });
  
      // Set distances to state
      setDistances(distancesMap);
  
      // Filter stores based on distance condition
      const filteredStoresWithDistance = res.filter(store => 
        !distancesMap[store._id] || distancesMap[store._id] <= 60
      );
  
      // Sort the filtered stores
      const sortedStores = filteredStoresWithDistance.map((store) => ({
        ...store,
        distance: calculateDistance(
          store.latitude,
          store.longitude,
          coordinates.lat,
          coordinates.lon
        ),
      })).sort((a, b) => a.distance - b.distance);
  
      // Update the state with sorted stores
      setFilteredRestaurants(sortedStores);
    }
  };
  
  useEffect(() => {
    if(location){
    calculateDistanceFromInput(location);
  }
  }, [location]);
  
  useEffect(() => {
    const fetchAllspecialites = async () => {
      try {
        const speaRes = await getAllspecialites();
        if (speaRes.length > 0) {
          setspecialite(speaRes);
          const AllStores = await getAllStoresActive();
          const  AllStoreInModeTest =  AllStores.filter(
            store => store.modetest === TestMode 
              //  && (store._id.toString()==="6787a808bf529e8ce963a350")
          )


          setAsllStores(AllStoreInModeTest);

          setFilteredRestaurants(AllStoreInModeTest);
        
        }
      } catch (err) {
        console.error("erre", err);
      }
    };
  
    fetchAllspecialites();
  }, []);
  
  const fetchData = async () => {
    try {
      let filtered = [];
  
      if (idSpecialite.length > 0) {
        filtered = AllStores.filter(restaurant =>
          idSpecialite.some(id => restaurant.specialites.includes(id))
        );
      } else {
        filtered = [...AllStores];
      }
  
      const uniqueFiltered = filtered.filter((store, index, self) =>
        index === self.findIndex(s => s._id === store._id)
      );
  
      // Add distance check here
      const distanceFiltered = uniqueFiltered.filter(store => 
        !distances[store._id] || distances[store._id] <= 60
      );
  
      setFilteredRestaurants(distanceFiltered);
  //  console.log("distanceFiltered",distanceFiltered)
      if (location !== null) {
        calculateDistanceFromInput2(location, distanceFiltered);
      }
    } catch (err) {
      console.error("Error", err);
    }
  };
  
  
  
  useEffect(() => {
    fetchData();
  }, [idSpecialite]);

  

  // const getIntoStore = (storeId,NameStore) => {
  const getIntoStore = (storeSlug) => {

    store.dispatch(setScroll({ scroll: 1}));   
    store.dispatch(setModesModal({ modesModal: true }));
    store.dispatch(setOrder({ order: [] }));
    store.dispatch(resetPromo());
      navigate(`/${storeSlug}`);
   // const nameStorewithId = NameStore.replace(/\s+/g, '-')+storeId.slice(-4);
    // navigate(`/${nameStorewithId}`);
  };
const isOpenNow = (storeRes) => {
    const { openingdate } = storeRes;
    const currentTime = new Date();
    const currentDay = currentTime.toLocaleString('en-US', { weekday: 'long' });
    const currentTimeString = currentTime.toLocaleTimeString('en-US', { hour12: false });
    return openingdate.some(({ shifts, jour }) => {
      const { start, end } = shifts;
      const isOpen = jour[currentDay]?.isOpen;
      if (!isOpen) return false;
      if (end < start) {
        const adjustedCurrentTime = new Date(currentTime);
        adjustedCurrentTime.setDate(adjustedCurrentTime.getDate() - 1);
        const adjustedCurrentTimeString = adjustedCurrentTime.toLocaleTimeString('en-US', { hour12: false });
        return currentTimeString >= start || currentTimeString <= end || adjustedCurrentTimeString >= start;
      } else {
        return currentTimeString >= start && currentTimeString <= end;
      }
    });
  };
  const handleSpecialiteClick = (id) => {
    if (idSpecialite.includes(id)) {
      setIdSpecialite(idSpecialite.filter(item => item !== id));
    } else {
      setIdSpecialite([...idSpecialite, id]);
    }
  };
const truncateAddress = (address) => {
  if (address.length > 35) {
    // Find the last space character within the first 15 characters
    const lastSpaceIndex = address.lastIndexOf(' ', 35);
    // Truncate the string up to the last space character
    return address.substring(0, lastSpaceIndex) + '...';
  } else {
    return address;
  }
};
  return (
    <div style={{ margin:'0px',backgroundColor:'#F8F9FA' }}>

      {/* <Banner /> */}
      <AdressInp  style={{width:'100%',alignItems:'center',justifyContent:'center',display:'flex' }}>
      <div style={{width:'85%',alignItems:'center',justifySelf:'center',display:'flex',flexDirection:'column'}}>
         <StyledHeading>
            RÉGALEZ-VOUS SUR EATORDER 
         </StyledHeading>
          <StyledSubHeading>
           LÀ OÙ CHAQUE SAVEUR RÉVÈLE UNE EXPÉRIENCE UNIQUE
          </StyledSubHeading>
        
        <AddressInput width={"43%"} onSelectLocation={onSelectLocation} calculateDistanceFn={calculateDistanceFromInput}  inputValue2={inputValue2}/>
        </div>
        
      </AdressInp>
      <StickyContainer>
     
        {specialite1 && specialite1.map((sp, index) => (
            <SpecialityItem
                key={index}
                isActive={idSpecialite.includes(sp._id)}
                onClick={() => handleSpecialiteClick(sp._id)}
            >
                <H1Styled >{sp.emoji}</H1Styled>
                <span>{sp.name}</span>
            </SpecialityItem>
        ))}
    </StickyContainer>
    <StoresContainer>
  {FilteredRestaurants.length === 0 ? (
    <p>No stores found</p>
  ) : (
    FilteredRestaurants.map((store) => (
      !distances[store._id] || distances[store._id] <= 60 ? (
       // <StoreInfoContainer key={store._id} onClick={() => getIntoStore(store._id,store.name)}>
            <StoreInfoContainer key={store._id} onClick={() => getIntoStore(store.slug)}>
          <img style={{ borderRadius: "16px", height: "11rem" }} src={`${ImageURI}/combined-uploads/${store.StoreBanner}`} alt="Company cover" />
          <LogoNameContainer>
            <StoreLogo src={`${ImageURI}/combined-uploads/${store.logo}`} alt="Store Logo" />
            <HCompanyName>{store.name}</HCompanyName>
          </LogoNameContainer>
          <div style={{ width: '100px!important', display: 'flex' }}>
            <div style={{ minWidth: '110px', minHeight: '20px' }}>
            </div>
            <Adressp>
              {truncateAddress(store.address)}
            </Adressp>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <DistanceLabel>
  {distances[store._id] > 0 && `${(distances[store._id] * 3.5 | 0)} min`} {distances[store._id] > 0 && t('away')}
</DistanceLabel>

            <ActiveStatus><h6>{isOpenNow(store) ? t('Open 🟢') : t('Closed 🔴')}</h6></ActiveStatus>
          </div>
          <Separator />
        </StoreInfoContainer>
      ) : null
    ))
  )}
</StoresContainer>
{/* <center><h1>Meilleurs magasins  de vente </h1></center>
<StoresContainer2> */}

{/* 
  {TpStores.length === 0 ? (
    <p>No stores found</p>
  ) : (
    TpStores.map((store) => (
      
       // <StoreInfoContainer key={store._id} onClick={() => getIntoStore(store._id,store.name)}>
            <StoreInfoContainer key={store._id} onClick={() => getIntoStore(store.slug)}>
          <img style={{ borderRadius: "16px", height: "11rem" }} src={`${ImageURI}/combined-uploads/${store.StoreBanner}`} alt="Company cover" />
          <LogoNameContainer>
            <StoreLogo src={`${ImageURI}/combined-uploads/${store.logo}`} alt="Store Logo" />
            <HCompanyName>{store.name}</HCompanyName>
          </LogoNameContainer>
          <div style={{ width: '100px!important', display: 'flex' }}>
            <div style={{ minWidth: '110px', minHeight: '20px' }}>
            </div>
            <Adressp>
              {truncateAddress(store.address)}
            </Adressp>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <DistanceLabel>
  {distances[store._id] > 0 && `${(distances[store._id] * 3.5 | 0)} min`} {distances[store._id] > 0 && t('away')}
</DistanceLabel>

            <ActiveStatus><h6>{isOpenNow(store) ? t('Open 🟢') : t('Closed 🔴')}</h6></ActiveStatus>
          </div>
          <Separator />
        </StoreInfoContainer>
    
    ))
  )} */}
{/* </StoresContainer2> */}
{/* <center><h1>Meilleurs produit de vente </h1></center> */}
{/* <ProductDetails>
                          {TpProduct?.map((product) =>
                           
                              <ProductCard
                                key={product._id}
                                // onClick={() => handleProductClick(product._id)}
                              >
                               
                                <ProductImage
                                  src={`${ImageURI}/combined-uploads/${product.image}`}
                                  alt={product?.name}
                                  loading="lazy"
                                />
                                <Wrapper>
                                  <StyledContent>
                                    <ProductTitle>{product?.name}</ProductTitle>
                                    <ProductDescription>
  {product && product.description ? (
    window.innerWidth > 768 ? (
      product.description.length > 120 ? (
        `${product.description.substring(0, 120)}...`
      ) : (
        product.description
      )
    ) : (
      product.description.length > 40 ? (
        `${product.description.substring(0, 40)}...`
      ) : (
        product.description
      )
    )
  ) : (
    "Description not available"
  )}
</ProductDescription>

                                  </StyledContent>
                                </Wrapper>
                              </ProductCard>
                        
                          )}
                        </ProductDetails> */}

</div>
  );
}



const Separator = styled.hr`
border: 0px solid gray;
@media (max-width: 768px) {
  border: 0.1rem solid gray;
}
`;
const DistanceLabel = styled.p`
  position: relative;
  color: #777;
  font-size: 1rem!important;
  @media (max-width: 768px) {
    font-size: 0.7rem!important;
  }
`;

const AdressInp = styled.div`
  background: linear-gradient(45deg, rgba(235, 23, 0, 1) 0%, rgba(238, 51, 31, 1) 33%, rgba(240, 65, 46, 1) 65%, rgba(235, 23, 0, 1) 100%);
  height: 600px;
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
 
  @media (max-width: 768px) {
    width: 80%;
    height: 360px;
  }
`;



const moveAnimationImageWithPause = keyframes`
  0%, 12.5% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  37.5% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const H1Styled = styled.h1`
    position: relative; /* Required for positioning the pseudo-element */
`;
const StickyContainer = styled.div`
padding-top:12px;
margin-bottom:40px;
background-color:white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
    
    display: flex;
    overflow-x: auto;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 60px; /* Adjust this to match the height of your fixed Container */
    background-color: white; /* Ensure it has a background color */
    z-index: 999; /* Ensure it appears above other elements */
     white-space: nowrap;
     max-height:300px;
     @media (min-width:676px) {
     justify-content: center;
      }
     user-select: none;
`;

const SpecialityItem = styled.div`
    margin: 5px 20px;
    cursor: pointer;
    color: ${({ isActive }) => (isActive ? 'rgb(235, 23, 0)' : 'inherit')};
    font-family: ${({ isActive }) => (isActive ? 'QuicksandBold!important' : 'inherit')};
    margin-top: ${({ isActive }) => (isActive ? '-5px' : '20px')};
    text-align: center;
    position: relative; /* Required for positioning the pseudo-element */

    h1 {
        animation: ${({ isActive }) => 
            isActive 
            ? css`${moveAnimationImageWithPause} 0.8s ease-in-out infinite` 
            : "none"};
        font-size: ${({ isActive }) => (isActive ? '36px' : '18px')};
        filter: ${({ isActive }) => (isActive ? 'drop-shadow(-2px 18px 8px rgba(0, 0, 0, 0.3))' : 'inherit')};
        position: relative; /* Required for positioning the pseudo-element */
    }

    span {
        font-size: ${({ isActive }) => (isActive ? '1rem' : '0.85rem')};
    }

    /* Add the colored circle behind the icon */
    h1::before {
        content: '';
        display: ${({ isActive }) => (isActive ? 'block' : 'none')}; /* Only show when active */
        width: 50px; /* Adjust the size as needed */
        height: 50px; /* Adjust the size as needed */
        background-color: rgba(235, 23, 0, 0.5); /* Change color and opacity as needed */
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1; /* Ensure it is behind the icon */
    }
`;


const StoresContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width:100%;
  align-items: center;
  justify-content: center;
  background-color:#fafafa;
  min-height:300px;
@media (min-width: 768px) {
margin:10px;
}
  @media (max-width: 767px) {
    flex-direction: column;
    max-width:100%;
    justify-content: center;
    align-items: center;
    
  }
    margin-bottom:60px;
`;



const StoreLogo = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px;
  margin-right: 20px;
  margin-top: -50px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;



const Adressp = styled.p`
  margin-bottom: 5px;
  margin-left: 50px;
  text-align: center;
  font-size: 1rem!important;
  @media (max-width: 768px) {
    font-size: 0.8rem!important;
  }
`;

const ActiveStatus = styled.div`
  text-align-last: right;
  font-size: 1rem!important;
  position: relative;
  top: 5px;
  right: 5px;
`;

const StoreInfoContainer = styled.div`
  display: flex;
  
  flex-direction: column;
  max-width: 400px;
  min-width: 400px;
  padding: 10px;
  
  
  border-radius: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767px) {
    max-width: 100%!important;
    min-width: 100%!important;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
  }
`;

const LogoNameContainer = styled.div`
margin-top:6px;
  display: flex;
  align-items: flex-end;
`;

const HCompanyName = styled.span`
  font-size: 1rem;
  margin-left: 0.5rem;
  text-align: center;
  color: #333;

  @media (max-width: 767px) {
    font-size: 1.3rem;
  }
`;

const StyledHeading = styled.h1`
  margin-bottom: 0px;
  color: #fff;
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StyledSubHeading = styled.h3`
  color: #fff;
  font-size: 1.6rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;


// Product Style  : 

