import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import InformationStore from "../../../../components/Menu/InformationStore";
import {
    setApply,
    setEditOrder,
    setFrais,
    setMenu,
    setMode,
    setModeName,
    setModeSelectedId,
    setOpenModal,
    setProduct,
    setRestaurantSelected,
    setRestaurantSlug,
    setTaux,
} from "../../../../shared/slice/restaurant/RestaurantSlice";
import {
    getMenuByStore,
    getMode,
    getModeConsomation,
    getProductByStoreByMode,
    getProductByStoreByModeWithout,
    getStoreById,
    getStoreID,
} from "../../../../shared/slice/restaurant/RestaurantService";
import Menu from "../../../../components/Menu/Menu";
import MyNavBar from "../../../../components/Navbar/MyNavBar";
import StoreNavbar from "../../../../components/StoreNavbar/StoreNavbar";
import { lighten } from "polished";
import ModalModes from "../../../../components/Modals/ModalModes";
import ModalStores from "../../../../components/Modals/ModalStores";
import { setModeDelivery, setModesModal } from "../../../../shared/slice/ModalMode/ModalMode";
import { BaseURI, ImageURI, store } from "../../../../shared";
// import { setScroll } from "../../../../shared/slice/scroll/ScrollSlice";
// import Cookies from 'js-cookie';
// import { setOrder } from "../../../../shared/slice/order/OrderSlice";
// import { disconnects, setLoggedInGuest, setToken } from "../../../../shared/slice/auth/AuthSlice";
// import { setModelGuest } from "../../../../shared/slice/ModalLogin/ModalLoginSlice";
import { setRootLoading } from "../../../../shared/slice/rootSlice";
import { socketRoald } from "../../../../App";
import { setOrder } from "../../../../shared/slice/order/OrderSlice";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2"; // Import SweetAlert2 for styled alerts
import { setmodalCheckout } from "../../../../shared/slice/auth/AuthSlice";
import { setOpenCheckOut } from "../../../../shared/slice/ModalStores/ModalStores";

export default function SelectStore() {
    const modesModal = useSelector((state) => state.modalMode.modesModal);
  
      const products = useSelector((state) => state.restaurant.product);

    const { id } = useParams();
    const IDSTORE = useParams().id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const restaurantSelected = useSelector(
        (state) => state.restaurant.restaurantSelected
    );
    const modeSelectedId = useSelector(
        (state) => state.restaurant.modeSelectedId
    );
    const orders = useSelector((state) => state.order.order);

    setModesModal(true)
    
    const modes = useSelector((state) => state.restaurant.mode);
    useEffect(() => {
        const FetchUpdatedMenu = async () => {
            try {
              
                const res2 = await getMenuByStore(restaurantSelected?._id);
                
                store.dispatch(setMenu({ menu: res2 }));
                
            } catch (error) {
                console.error("Error fetching updated menu:", error);
            }
        };

          const decryptData = (encryptedData) => {
            try {
              const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
              const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        
              // Check if the decrypted text is valid JSON
              if (!decryptedText) {
                throw new Error("Decryption failed or returned empty string.");
              }
        
              return JSON.parse(decryptedText);
            } catch (error) {
              console.error("Error during decryption or parsing:", error);
              return null; // or handle the error as appropriate
            }
          };
            const encryptData = (data) => {
              return CryptoJS.AES.encrypt(JSON.stringify(data), "secret_key").toString();
            };
          
            const deleteItemCompletely = (productId, orderKey) => {

                // Retrieve and decrypt order data from localStorage for the specific store
                const cryptedOrder = localStorage.getItem("OrderStoreWithID" + IDSTORE);
                let allOrders = [];
              
                // If there's encrypted data, decrypt it
                if (cryptedOrder) {
                  allOrders = decryptData(cryptedOrder);
                }
              
                // Filter orders to remove the item with the matching orderKey
                const updatedOrders = orders
                  .map((order) => {
                    if (order.orderKey === orderKey) {
                      // Find the corresponding order in allOrders by orderKey
                      const orderFromAllOrdersIndex = allOrders.findIndex(
                        (thisOrder) => thisOrder.orderKey === order.orderKey
                      );
              
                      // Remove the item from localStorage
                      const localStorageKey = `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${productId}`;
                      localStorage.removeItem(localStorageKey);
              
                      // Remove the order from allOrders
                      allOrders.splice(orderFromAllOrdersIndex, 1);
              
                      // Return null to exclude this order from the updated array
                      return null;
                    }
              
                    // Return the unmodified order for non-matching items
                    return order;
                  })
                  .filter(Boolean); // Remove null entries (i.e., deleted orders)
              
                // Dispatch updated orders to Redux store
                store.dispatch(setOrder({ order: updatedOrders }));
              
                // Encrypt and store the updated allOrders in localStorage
                const encryptedOrder = encryptData(allOrders);
                localStorage.setItem(`OrderStoreWithID${IDSTORE}`, encryptedOrder);
              };
              
          
    
        const FetchUpdatedProduct = async () => {
            try {
     const allOrdersWillDelete =[];

                const res33 = await getProductByStoreByModeWithout(restaurantSelected?._id, modeSelectedId);
             
                for (let i = 0; i <orders.length; i++) {
                    const productEqOption = res33.find((p) => p._id.toString() === orders[i].product.id);
                    const productEqOption02 = products.find((p) => p._id.toString() === orders[i].product.id);

                if (JSON.stringify(productEqOption) === JSON.stringify(productEqOption02)){
                  
                    continue;
                }else{
                    const OrdersWillDelete ={
                        orderKey: orders[i].orderKey,
                        product: orders[i].product
                    }  
                    allOrdersWillDelete.push(OrdersWillDelete)
                    //(orders[i].product.id,orders[i].orderKey)
                   
                }
             
                    //  if(productEqOption.availability===false||productEqOption.availabilitys[0].availability===false){
                    //     deleteItemCompletely(orders[i].product.id,orders[i].orderKey)
                    //return;
                    //    }
                }
                store.dispatch(setProduct({ product: res33 }));

                const deletedProductNames = [];
                allOrdersWillDelete.forEach(({ product, orderKey }) => {
                    deleteItemCompletely(product.id, orderKey); // Call the delete function
                    deletedProductNames.push(product.name); // Collect product name
                });
             



        
                // Display deleted product names in a styled alert
                if (deletedProductNames.length > 0) {
                    Swal.fire({
                        title: "Products Removed",
                        html: `<ul>${deletedProductNames.map(name => `<li>${name}</li>`).join("")}</ul>`,
                        icon: "info",
                        confirmButtonText: "OK",
                        customClass: {
                            popup: "custom-alert-popup",
                        },
                    });
                }

               
            } catch (error) {
                console.error("Error fetching updated product:", error);
            }
        };
    
        const fetchUpdatedBoth = async () => {
            
            await FetchUpdatedMenu();
            await FetchUpdatedProduct();
        
        };
    
        socketRoald.on('UpdatedMenu' + restaurantSelected?._id, fetchUpdatedBoth);
        socketRoald.on('UpdatedProduct' + restaurantSelected?._id, fetchUpdatedBoth);
        socketRoald.on('UpdatedBoth' + restaurantSelected?._id, fetchUpdatedBoth);
    
        return () => {
            socketRoald.off('UpdatedMenu' + restaurantSelected?._id, fetchUpdatedBoth);
            socketRoald.off('UpdatedProduct' + restaurantSelected?._id, fetchUpdatedBoth);
            socketRoald.off('UpdatedBoth' + restaurantSelected?._id, fetchUpdatedBoth);
        };
    }, [socketRoald, restaurantSelected?._id,modeSelectedId]); 
    
    



    useEffect(() => {
        const getmode = async () => {
            if (modeSelectedId) {
                const mode = await getMode(modeSelectedId);
                if (mode.mode.applyTaux) {
                    dispatch(setTaux({ taux: mode.mode.taux }));
                } else {
                    dispatch(setFrais({ frais: mode.mode.frais }));
                }
                dispatch(setApply({ apply: mode.mode.applicationType }))
                dispatch(setModeName({ modeName: mode.mode.name }))
            }
        };
        getmode();
    }, [modeSelectedId, restaurantSelected?._id]);
    // const scroll = useSelector((state) => state.scroll.scroll);

 
    const preloadImages = (filteredCategories) => {
        filteredCategories.forEach((category) => {
          const img = new Image();
          img.src = `${ImageURI}/combined-uploads/${category.image}`;
        });
      };
    
    useEffect(() => {
    
        const fetchedStoresById = async () => {
            

            store.dispatch(setRootLoading(false));
                 store.dispatch(setmodalCheckout({ modalCheckout: false }));
               store.dispatch(setOpenCheckOut({ setOpenCheckOut: false }));
           
      
            //document.documentElement.style.overflow = 'hidden';
            // console.log("--------------------------",ModelGuest)
            // store.dispatch(setToken({}));
            // store.dispatch(setLoggedInGuest({ user: {} }));
            store.dispatch(setModeDelivery({ modeDelivery: false }));
            store.dispatch(setOpenModal({
                product: undefined,
                open: false,
              }));
            
            store.dispatch(setEditOrder(
                {
                   open: false,
                   objectOptions:[],
                   checkedIndices: [],
                   checkedOptions: [],
                   counter:{},
                   orderQuantity:0,
                   note:undefined,
                   size:""
               }
               ));
            store.dispatch(setModesModal({ modesModal: true }));
           const StroreeId = await getStoreID(id) 
    
            getModeConsomation(StroreeId)
            .then((res4) => {
                dispatch(setMode({ mode: res4.consumationModes }));
                // console.log(res4.consumationModes);
            })
            .catch((err) => {
                // console.log("Page not found");
             navigate(`/page404`);
            });


            getStoreById(StroreeId)
                .then(async (res) => {
                    
                    dispatch(setRestaurantSelected({ restaurantSelected: res }));
                    // dispatch(setModeSelectedId({ modeSelectedId: res.defaultMode }))
                    
                    dispatch(setModeSelectedId({ modeSelectedId: modeSelectedId }));
                    document.documentElement.style.setProperty(
                        "--primaryColor",
                        res.primairecolor
                    );
                    document.documentElement.style.setProperty(
                        "--primaryColorLight",
                        lighten("0.3", res.primairecolor)
                    );

                    getMenuByStore(StroreeId)
                        .then((res2) => {
                            dispatch(setMenu({ menu: res2 }));
                            preloadImages( res2?.categorys);

                        })
                        .catch((err) => {
                            console.log("Page not found");
                         navigate(`/page404`);
                        });
                       

                        
                        // const storedOrders = localStorage.getItem('OrderStoreWithID'+id);
                        // const orders = JSON.parse(storedOrders); // Convert to JavaScript object

                        // if (orders) {
                        //     dispatch(setOrder({ order: orders }));
                        // }




                })
                .catch((err) => {
                    console.log("Page not found");
                     navigate(`/page404`);
                });
        };

        fetchedStoresById();
    }, []);

    return (
      <div>  {restaurantSelected && modes ? (
            <div>
                <div style={{  
                    position: "sticky",
                    top: "0",
                    zIndex: "1005",
                    backgroundColor: "white"
                }}>
                    <MyNavBar />
                    <ModalModes />
                    <StoreNavbar />
                    <ModalStores />
                </div>
                <Menu />
            </div>
        ) : (
            <div>
                {modes && 
                <ModalModes />}
            </div>
        )}
        </div>
    );
}
