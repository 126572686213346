import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { store } from "../../../../shared";
import { setScroll } from "../../../../shared/slice/scroll/ScrollSlice";
import { setModesModal } from "../../../../shared/slice/ModalMode/ModalMode";
import { setOrder } from "../../../../shared/slice/order/OrderSlice";
import { resetPromo } from "../../../../shared/slice/promos/PromosSlice";
import { setmodalCheckout } from "../../../../shared/slice/auth/AuthSlice";

export default function QrCodePage() {
    const { QrCode,vendeurId,vendeur} = useParams();

    useEffect(() => {
        // Dispatch various actions to update the global state
        store.dispatch(setScroll({ scroll: 1 }));
        store.dispatch(setModesModal({ modesModal: true }));
        store.dispatch(setOrder({ order: [] }));
        store.dispatch(resetPromo());
      store.dispatch(setmodalCheckout({ modalCheckout: false }));

        // Log the QR Code for debugging purposes
        // console.log("QR Code:", QrCode);
        // console.log("vendeur:", vendeur);
        // console.log("vendeurId:", vendeurId);
    }, [QrCode]);

    // Conditionally navigate based on the QrCode value
    if (QrCode&&vendeurId&&vendeur) {
        return <Navigate to={`/${QrCode}`} state={{ delivery: false ,vendeur:vendeur ,vendeurId:vendeurId}} />;
    }

    // Return null or a loading component if QrCode is not yet available
    return null;
}