import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getpromosbystoreid } from "../../shared/slice/promos/PromosService";
import { BaseURI, ImageURI, store } from "../../shared";
import {
    setNiveauPromos,
    setPromos,
    setSizeSelected,
} from "../../shared/slice/promos/PromosSlice";
import { PromoModal } from "./PromoModal/PromoModal";
import styled from "styled-components";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { useTranslation } from "react-i18next";
import PromoDescriptionModal from "./PromoModal/PromoDescriptionModal";

export default function Promo() {
    const { t } = useTranslation();
    // const scroll = useSelector((state) => state.scroll.scroll)
    const storeId = useSelector(
        (state) => state.restaurant.restaurantSelected._id
    );
    const promos = useSelector((state) => state.promos.promos);
    const modeSelectedId = useSelector((state) => state.restaurant.modeSelectedId);
    const products = useSelector((state) => state.restaurant.product);
    const dispatch = useDispatch();

    useEffect(() => {

        const fetchPromos = async () => {
            try {
                if(modeSelectedId!==undefined && modeSelectedId!=='') { 
                const res = await getpromosbystoreid(storeId, modeSelectedId);
            
                          const Promos = res.promos
                          const Final = []
                          for(let i = 0; i < Promos.length; i++) { 
                            const ll = []
                            
                            for(let j = 0; j < Promos[i].promos.length; j++) { 
                                    
                                const kk = []

                                for(let k = 0; k < Promos[i].promos[j].products.length; k++) { 
                                    const FUllProduct=products.find((product) => product._id === Promos[i].promos[j].products[k]);
                                    kk.push(FUllProduct)
                                 }
                                 const jj = {
                                    AcceptedSizes: Promos[i].promos[j].AcceptedSizes,
                                    category: Promos[i].promos[j].category,
                                    order: Promos[i].promos[j].order,
                                    _id: Promos[i].promos[j]._id,
                                    products: kk,
                                 }
                                 ll.push(jj);
                          }
                          
                          const tt = {
                            availability:Promos[i].availability,
                            availabilitys:Promos[i].availabilitys,
                            discount:Promos[i].discount,
                            promoId:Promos[i]._id,
                            image:Promos[i].image,
                            name:Promos[i].name,
                            number2:Promos[i].number2,
                            numberGroup:Promos[i].numberGroup,
                            promos:ll,
                            bundlePrice:Promos[i].bundlePrice,
                            sameSize:Promos[i].sameSize,
                         }
                         Final.push(tt)      
                        }
                store.dispatch(setPromos({ promos: Final }));
            }
            } catch (err) {
            }
            
        };
        fetchPromos();
    }, [modeSelectedId, storeId]);



    const [selectedPromo, setSelectedPromo] = useState({
        promoDiscription: false,
        state: false,
        promo: undefined,
        products: [{ product: undefined, selected: false }],
    });
    return (
        <div>
            {selectedPromo?.state && (
                <PromoModal
                    selectedPromo={selectedPromo}
                    setSelectedPromo={setSelectedPromo}
                />
            )}
            {selectedPromo?.promoDiscription && (
                <PromoDescriptionModal
                    selectedPromo={selectedPromo}
                    setSelectedPromo={setSelectedPromo}
                />
            )}
            {promos.length > 0 && (<div
                id={promos[0]?._id}
                style={{
                    margin: "20px",
                    fontSize: "30px",
                }}
            >
                {t('Our promos')}           </div>)}
            <Container>
                <PromoStyled>
                    {promos?.map((promo) => (
                        <PromoItem
                            key={promo._id}
                            onClick={() => {
                                dispatch(setNiveauPromos({ NiveauPromos: 0 }));
                                dispatch(setSizeSelected({ SizeSelected: ''}));
                  
                                setSelectedPromo({
                                    ...selectedPromo,
                                    promo: promo,
                                   promoDiscription: promo.sameSize,
                                    state: !promo.sameSize,
                                });
                                store.dispatch(setScroll({ scroll: 1}));
                            }}
                        >
                            <PromoImage src={`${ImageURI}/combined-uploads/${promo.image}`} alt={promo.name} width ="250px" height="200px" />
                        </PromoItem>
                    ))}
                </PromoStyled>
            </Container>
        </div>
    );
}
const Container = styled.div`
  width: 100%;
  overflow-x: auto;

`;
const PromoStyled = styled.div`
  display: flex;
`;
const PromoItem = styled.div`
  display: flex;
  justify-content: center;
`;
const PromoImage = styled.img`
  margin-right: 10px;
  margin-left: 20px;
  max-width: 300px;
  /* width : 300px;
  height : 240px;
  @media (max-width: 768px) {
    max-width: 250px;
    width : 250px;
    height : 200px;
  } */
`;










