
  import { getMessaging, getToken } from 'firebase/messaging';
  import { initializeApp } from 'firebase/app';

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBOmZ_Godsp5BpPTg5VeRWctObtHJYKpPM",
    authDomain: "eator-b7774.firebaseapp.com",
    projectId: "eator-b7774",
    storageBucket: "eator-b7774.firebasestorage.app",
    messagingSenderId: "124578793915",
    appId: "1:124578793915:web:4bf30f0c6674a07781dc9e",
    measurementId: "G-NZ87KK89MF"
  };

  const app = initializeApp(firebaseConfig);
  export const messaging = getMessaging(app);


  export const generateToken = async () => {


    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const registration = await navigator.serviceWorker.ready;
        const token = await getToken(messaging, {
          vapidKey: "BDLQM5FR-LIBPC0cWj_te6E8klBXEZB1lVgTbaRdYcNtPhrIaYKDcHmyYZviMxOEbRoMbChFhe4eOxl9Eg935y8",
          serviceWorkerRegistration: registration

        });

        if (token) {
          // console.log("Notification Token:", token);
          localStorage.setItem("firebaseToken", token);

        }
      } catch (error) {
        // window.location.reload(); // Reload the page if token generation fails
        console.error("Error getting token", error);
      }
    } else {
      console.error("Notification permission denied.");
    }
  };

  // You can call this function inside a useEffect or elsewhere to ensure the token is generated.
