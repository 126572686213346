import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ImageURI, store } from "../../shared";
import styled, { keyframes, css } from "styled-components"; // Import keyframes and css from styled-components
import tw from "twin.macro";
import getSymbolFromCurrency from "currency-symbol-map";
import ProductModal from "./productModal/ProductModal";
import Promo from "../Promo/Promo";
import { PromoImage } from "../../assets/images/exports";
import { useTranslation } from "react-i18next";
import { setOpenModal } from "../../shared/slice/restaurant/RestaurantSlice";
import { useParams } from "react-router-dom";

export default function Menu() {
  const { t } = useTranslation();
  const [closestCategory, setClosestCategory] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [isManualScroll, setManualScroll] = useState(false); // New state


  const { id } = useParams();
  const menu = useSelector((state) => state.restaurant.menu);
  const openModal = useSelector((state) => state.restaurant.openModal);
  const promo = useSelector((state) => state.promos.promos);
  const categories = menu?.categorys;
  const products = useSelector((state) => state.restaurant.product);

  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  

  // const defaultCategoryId =categories && categories.length > 0 ? categories[0]._id : null;
  const modesFinal = useSelector((state) => state.restaurant.modeSelectedId);
  const containerCategoriesRef = useRef(null); // Reference to ContainerCategories

  useEffect(() => {
    if (categories) {
      const filteredCategories = categories?.filter(
        (category) =>
          category.products.length > 0 &&
          category.availability &&
          category.availabilitys.some(
            (avail) => avail.mode === modesFinal && avail.availability
          )
      );
      if (window.scrollY < 250) {
        if (promo[0]) {
          setClosestCategory(promo[0]?._id);
        } else {
          setClosestCategory(filteredCategories[0]?._id);
        }
      }
    }
  }, []);
  useEffect(() => {
    let timeoutId; // Timeout reference for debouncing
  
    const handleScroll = () => {
      // Clear the previous timeout to debounce
      clearTimeout(timeoutId);
  
      // Set a new timeout to delay execution
      timeoutId = setTimeout(() => {
        setScrollTop(window.scrollY);
  
        const filteredCategories = categories.filter(
          (category) =>
            category.products.length > 0 &&
            category.availability &&
            category.availabilitys.some(
              (avail) => avail.mode === modesFinal && avail.availability
            )
        );
  
        const scrolledToBottom =
          window.innerHeight + window.scrollY + 2 >
          document.documentElement.scrollHeight;
  
        if (window.scrollY < 250) {
          if (promo[0]) {
            if (isManualScroll === true) return;
            setClosestCategory(promo[0]?._id);
          } else {
            if (isManualScroll === true) return;
            setClosestCategory(filteredCategories[0]?._id);
          }
        } else if (scrolledToBottom) {
          if (isManualScroll === true) return;
          setClosestCategory(
            filteredCategories[filteredCategories.length - 1]?._id
          );
        } else {
          // Filter out null elements and map distances only for elements in the DOM
          const categoryElements = filteredCategories
            .map((category) => document.getElementById(category._id))
            .filter((element) => element !== null); // Only non-null elements
  
          const distances = categoryElements.map((element) => {
            const rect = element.getBoundingClientRect();
            const centerY =
              window.innerHeight / 2 + (window.innerWidth < 1023 ? 150 : 0) - 0;
            const elementCenterY = rect.top + rect.height / 2;
            return Math.abs(centerY - elementCenterY);
          });
  
          if (distances.length > 0) {
            const minDistance = Math.min(...distances);
            const closestIndex = distances.findIndex(
              (distance) => distance === minDistance
            );
            // console.log("isManualScroll", isManualScroll);
            if (isManualScroll === true) return;
            setClosestCategory(filteredCategories[closestIndex]?._id);
          }
        }
      }, 15); // 200ms delay
    };
  
    // Attach the event listener
    window.addEventListener("scroll", handleScroll);
  
    // Cleanup function to remove listener and clear timeout
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, [categories, promo, scrollTop, isManualScroll]);
  
  

  useEffect(() => {
    const handleAutomaticScroll = () => {
            if (containerCategoriesRef.current) {
        const container = containerCategoriesRef.current;
        if (closestCategory === promo[0]?._id) {
          // Scroll the container to the top if it's the promo
          if (window.innerWidth < 1024) {
            container.scrollTo({
              left: 0,
              behavior: "smooth",
            });
          }
          if (window.innerWidth >= 1024) {
            container.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        } else {
          // Find the category element by comparing IDs
          const categoryElement = Array.from(container.children).find(
            (child) => child.getAttribute("data-id") === closestCategory
          );

          if (categoryElement) {
            const containerRect = container.getBoundingClientRect();
            const categoryRect = categoryElement.getBoundingClientRect();

            let scrollOffset = 0;

            // Calculate the scroll position based on window width and scroll direction
            if (window.innerWidth < 1024) {
              // Horizontal scrolling logic
              scrollOffset =
                categoryRect.left - containerRect.left + container.scrollLeft;
            } else {
              // Vertical scrolling logic
              scrollOffset =
                categoryRect.top - containerRect.top + container.scrollTop;
            }

            container.scrollTo({
              left: window.innerWidth < 1024 ? scrollOffset : 0,
              top: window.innerWidth >= 1024 ? scrollOffset : 0,
              behavior: "smooth",
            });
          }
        }
      }
    };
    handleAutomaticScroll();
  }, [closestCategory]);

  const handleProductClick = (productId) => {
    const product = products.find((product) => product._id === productId);
    store.dispatch(setOpenModal({ product, open: true }));
  };

  const scrollToElement2 = (id) => {
   window.scrollTo({ top: 0, behavior: "instant" });
  };
  const scrollToElement = (id) => {
    setManualScroll(true); 

    setClosestCategory(id);
    const element = document.getElementById(id);
    if (element) {
      let offset;
      if (window.innerWidth < 1023) {
        offset = element.offsetTop - (56 + 73.5 + 40);
      } else {
        offset = element.offsetTop - (73.5 + 75);
      }
      window.scrollTo({ top: offset, behavior: "instant" });
    }
    setTimeout(() => setManualScroll(false), 50);
  };

  return (
    categories &&
    products && (
      <HomeContainer>
        <ContainerCategories
          ref={containerCategoriesRef}
          id="containerCategories"
        >
          {promo.length > 0 && (
            <CategoryWrapper
              key={0}
              data-id={promo[0]?._id}
              onClick={() => scrollToElement2(promo[0]?._id)}
              selected={closestCategory === promo[0]?._id}
            >
              <CategoryImage
                src={PromoImage}
                alt="Our promos"
                width="50px"
                height="50px"
                selected={closestCategory === promo[0]?._id}
              />
              <CategoryName selected={closestCategory === promo[0]?._id}>
                {t("Our promos")}
              </CategoryName>
            </CategoryWrapper>
          )}
     {categories.map((category, index) => {
  // Filter products based on availability and visibility conditions
  const validProducts = category.products.filter((productId) => {
    // Find the product object from the Redux state
    const product = products.find((p) => p._id === productId);

    if (!product) return false; // Skip if the product is not found

    // Extract availability and visibility conditions
    const isAvailable =
      product.availabilitys?.[0]?.availability && product.availability;
    const productVisibility = product.visibility;

    // Exclude products that meet the exclusion condition
    return !(isAvailable === false && productVisibility === false);
  });

  // Skip rendering if no valid products remain or category is not available
  if (validProducts.length === 0 || !category.availability) return null;

  // Render the category if it meets all conditions
  return category.availabilitys.map((avail) => {
    if (avail.mode === modesFinal && avail.availability) {
      return (
        <CategoryWrapper
          key={index}
          data-id={category._id}
          onClick={() => scrollToElement(category._id)}
          selected={closestCategory === category._id}
        >
          <CategoryImage
            selected={closestCategory === category._id}
            src={`${ImageURI}/combined-uploads/${category.image}`}
            alt={`${category.name}`}
            width="50px"
            height="50px"
          />
          <CategoryName selected={closestCategory === category._id}>
            {category.name}
          </CategoryName>
        </CategoryWrapper>
      );
    }
    return null; // Skip if availability conditions are not met
  });
})}

        </ContainerCategories>

        <AllProducts>
          <Promo />
          <div>
          {categories.map((category) => {
  // Filter products for the current category
  const validProducts = category.products.filter((productId) => {
    // Find the product object from Redux state
    const product = products.find((p) => p._id === productId);

    if (!product) return false; // Skip if the product is not found

    // Extract availability and visibility conditions
    const isAvailable =
      product.availabilitys?.[0]?.availability && product.availability;
    const productVisibility = product.visibility;

    // Exclude products that meet the exclusion condition
    return !(isAvailable === false && productVisibility === false);
  });

  // Skip rendering if no valid products remain or category is not available
  if (validProducts.length === 0 || !category.availability) return null;

  // Render the category if it meets the availability conditions
  return category.availabilitys.map((avail) => {
    if (avail.mode === modesFinal && avail.availability) {
      return (
        <div key={category._id} id={category._id}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "95%",
            }}
          >
            <Line />
            <ProductCategories>{category.name}</ProductCategories>
            <Line />
          </div>
          <ProductDetails>
            {validProducts.map((productId) => {
              // Find the product object from Redux state
              const product = products.find((p) => p._id === productId);

              if (!product) return null; // Skip if the product is not found

              // Check availability
              const isAvailable =
                product.availabilitys?.[0]?.availability &&
                product.availability;

              return (
                <ProductCard
                  key={product._id}
                  onClick={isAvailable ? () => handleProductClick(product._id) : null}
                  style={{
                    cursor: isAvailable ? "pointer" : "not-allowed",
                    opacity: isAvailable ? 1 : 0.6,
                    position: "relative", // Required for the ribbon's absolute positioning
                  }}
                >
                  {!isAvailable && <Ribbon content={t("Unavailable")} />}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {(() => {
                      const productId = product._id;
                      const quantityKey = `QuantityInCart_ForStoreWithId${id}_ForProductWith${productId}`;
                      const quantityInCart = localStorage.getItem(quantityKey);
                      return (
                        quantityInCart &&
                        parseInt(quantityInCart) > 0 && (
                          <div>
                            <CardBadge>{parseInt(quantityInCart)}</CardBadge>
                          </div>
                        )
                      );
                    })()}
                  </div>
                  <ProductImage
                    src={`${ImageURI}/combined-uploads/${product.image}`}
                    onError={(e) => (e.currentTarget.src = `${ImageURI}/combined-uploads/default.svg`)}
                    alt={product.name}
                    loading="lazy"
                    height="144"
                    width="144"
                    style={{
                      filter: isAvailable ? "none" : "grayscale(100%)",
                    }}
                  />
                  <Wrapper>
                    <StyledContent>
                      <ProductTitle>{product.name}</ProductTitle>
                      <div style={{ margin: "3px 0px" }}>
                        {product.size[0].price>0 && 
                        <ProductPrice>
                          {product.size?.length > 1 && t("From ")}
                          {product?.size?.length >= 2 && 
                          product?.size[0].name === "standard"
                            ? apply === "product"
                              ? parseFloat(
                                  product.size[1]?.price +
                                  (frais !== null
                                    ? frais
                                    : product.size[0]?.price * (taux / 100))
                                ).toFixed(2)
                              : parseFloat(product.size[1]?.price).toFixed(2)
                            : apply === "product"
                            ? parseFloat(
                                product.size[0]?.price +
                                (frais !== null
                                  ? frais
                                  : product.size[0]?.price * (taux / 100))
                              ).toFixed(2)
                            : parseFloat(product.size[0]?.price).toFixed(2)}{" "}
                          {getSymbolFromCurrency(menu.currency)}
                        </ProductPrice>}
                      </div>
                      <ProductDescription>
                        {window.innerWidth > 768
                          ? product.description.length > 120
                            ? `${product.description.substring(0, 120)}...`
                            : product.description
                          : product.description.length > 40
                          ? `${product.description.substring(0, 40)}...`
                          : product.description}
                      </ProductDescription>
                    </StyledContent>
                  </Wrapper>
                </ProductCard>
              );
            })}
          </ProductDetails>
        </div>
      );
    }
    return null;
  });
})}

          </div>
        </AllProducts>
        {openModal?.open && <ProductModal />}
      </HomeContainer>
    )
  );
}
const Ribbon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: 1024px) {
    width: 100%;
    height: 62%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    height: 62%;
  }
  @media (max-width: 768px)  {
    width: 100%;
    height: 65%;
  }

  pointer-events: none; /* Make sure the ribbon doesn't interfere with clicks */
  display: flex;
  justify-content: center;
  align-items: center;
z-index: 1000;
  &::before {
    content: ${({ content }) => `'${content}'`}; /* Dynamically set content */
    position: absolute;
    transform: rotate(-45deg);
    background: rgba(255, 0, 0, 0.6); /* Red ribbon with transparency */
    color: white;
    font-size: 14px;
    font-weight: bold;
    @media (min-width: 1024px) {
      padding: 5px 19%;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    padding: 5px 25%;
  }
  @media (max-width: 768px)  {
    padding: 5px 14%;
  }
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const CardBadge = styled.h6`
  min-width: 30px;
  min-height: 30px;
  background-color: var(--primaryColor);
  border-radius: 50%;

  position: absolute;
  margin-top: -0.8rem;
  margin-left: -1rem;

  color: #fff;

  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
`;
const HomeContainer = styled.div`
  display: flex;
  margin-left: 2%;
  @media (max-width: 768px) {
    display: block;
    margin-left: 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display: block;
    margin-left: 0;
  }
`;
//sidebar
const ContainerCategories = styled.div`
  z-index: 1001;

  width: 20%;
  margin-right: 5%;
  overflow-y: auto; /* Add vertical scrollbar if necessary */
  max-height: calc(100vh - 127px); /* Subtract the height of the navbar */
  position: sticky;
  top: 13%;
  scrollbar-width: none;

  // border:1px solid;
  @media (min-width: 1024px) {
    max-width: 250px;
  }

  @media (max-width: 768px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    width: 100%;
    overflow-x: auto;
    position: sticky;
    top: 127px;
    background: #fff;
    z-index: 2;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 100%;
    overflow-x: auto;
    position: sticky;
    top: 130px;
    background: #fff;
   
    z-index: 2;
  }
  position: sticky;
  top: 13%;

  @media (max-width: 768px) {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
  }
`;

const CategoryName = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
  color: ${(props) => (props.selected ? "var(--primaryColor)" : "#000")};
  cursor: pointer;
  user-select: none;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const moveAnimationPC = keyframes`
0% {
  transform: translateX(0);
}
50% {
  transform: translateX(20px); // Example movement
}
100% {
  transform: translateX(0);
}
`;
const moveAnimationMobile = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(-5px); // Example movement
}
100% {
  transform: translateY(0);
}
`;
const CategoryWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selected ? "solid 3px var(--primaryColor)" : null};

  @media (min-width: 1023px) {
    border-bottom: ${(props) =>
      props.selected ? "solid 3px var(--primaryColor)" : null};
    animation: ${(props) =>
      props.selected
        ? css`
            ${moveAnimationPC} 1s ease-in-out
          `
        : "none"};
  }
  @media (max-width: 1023px) {
    border-bottom: ${(props) =>
      props.selected ? "solid 3px var(--primaryColor)" : null};
    animation: ${(props) =>
      props.selected
        ? css`
            ${moveAnimationMobile} 1s ease-in-out
          `
        : "none"};
  }
  @media (max-width: 768px) {
    border-top: solid 3px lightgray;
    width: 100%;
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: none;
    border-bottom: ${(props) =>
      props.selected ? "solid 3px var(--primaryColor)" : null};
    color: ${(props) => (props.selected ? "var(--primaryColor)" : null)};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    border-bottom: none;
    justify-content: center;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: none;
    border-bottom: ${(props) =>
      props.selected ? "solid 3px var(--primaryColor)" : null};

    color: ${(props) => (props.selected ? "var(--primaryColor)" : null)};
  }
`;
const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
  animation: ${(props) =>
    props.selected
      ? css`
          ${moveAnimationImage} 1s ease-in-out infinite
        `
      : null};
`;

const moveAnimationImage = keyframes`
   0%, 12.5% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  37.5% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

//products

const AllProducts = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
`;
const ProductCategories = styled.h2`
  text-transform: capitalize;
  font-size: 36px;
  margin: 40px 20px 20px 20px;
  text-align: center;
  display: inline-block;
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

const ProductDetails = styled.div`
  ${tw`text-center font-bold `}
  display:flex;
  flex-wrap: wrap;

  @media (max-width: 1127px) {
    /* margin-left: 3%; */
    justify-content: center;
  }
`;
const ProductCard = styled.div`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width:250px;
  height: auto;
  @media (min-width: 785px) and (max-width: 900px) {
    width: 43%;
  }
  padding: 1%;
  margin: 15px;
  border-radius: 10px;
  @media (max-width: 785px) {
    width: 43%;
    margin: 10px;
  }
  &:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
`;

const ProductImage = styled.img`
  object-fit: contain;
  display: inline;
height: 144px;
width: 144px;
  margin: 0px 0px 10px 0px;

  @media (min-width: 785px) and (max-width: 900px) {
    height: 200px;
    width: 200px;
  }
`;

const StyledContent = styled.div`
  // ${tw`py-4`}
  padding-top: 10px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ProductTitle = styled.div`
  ${tw`capitalize`}
  font-size:24px;
  font-weight: 900;
  height: auto;
  min-height: 40px;
  overflow-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 18px;
    height: auto;
  }
`;
const ProductDescription = styled.p`
  text-align: center; /* Center text */
  color: black;
  flex-grow: 4; /* Allow this item to grow */
  
  /* Set the element to a block and center it */
  display: flex; /* Change to flex to allow centering */
  justify-content: center; /* Center contents horizontally */
  align-items: center; /* Center contents vertically, if applicable */
  
  width: 100%; /* Full width of the parent */
  height: auto; /* Auto height */
  max-width: 300px; /* Maximum width */
  word-wrap: break-word; /* Handle long words */
  
  padding-left: 2%; /* Padding on left */
  margin-top: 10px; /* Margin on top */
  margin-bottom: 5px !important; /* Bottom margin */
  font-size: 14px; /* Font size */
  font-family: QuickSandLight; /* Font family */
  overflow-wrap: break-word; /* Break long words */
  
  @media (max-width: 768px) {
    font-size: 0.7rem; /* Responsive font size */
  }
`;

const ProductPrice = styled.p`
  // float: right;
  margin: 3px 0px;
  font-size: 1.1rem;
  color: #6c6c6c;

  font-family: QuickSandMedium;
  // font-style: italic;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;





const Line = styled.div`
  // display: inline-block;
  border-bottom: 3px solid #cccccc;
  width: 40%;
  margin-left: 15px;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 25%;
  }
`;
