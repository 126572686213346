export default {
    //home.js
    "Our Partners": "Nuestros socios",

    //AdressInput.js
    "type your address": "escribe tu dirección",

    //StoreScreen.js
    "km away": " km ",
    "Closed 🔴": "Cerrado 🔴",
    "Open 🟢": "Abierto 🟢",

    //DateModel.js
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Closed": "Cerrado",
    "Service Hours": "Horario de servicio",
    "Language": "Idioma",

    //menu.js
    "Our promos": "Nuestras promociones",

    //selectoption.js
    "(optional)": "(Opcional)",
    "Required": "Requerido",
    "select up to": "seleccionar hasta",

    //SubmitOrder.js
    "select at least": "selecciona al menos",
    "of": "de",
    "Quantity": "Cantidad",
    "change your order": "cambia tu orden",

    //submitOrderPromo.js
    "Finish": "Terminar",
    "Next": "Siguiente",

    //ModelPrincipal.js
    "Email Address": "Dirección de correo electrónico",
    "Continue": "Continuar",
    "Or": "O",
    "Continue with Google": "Continuar con Google",
    "Email does not exist": "El correo electrónico no existe",

    //ModalSignup.js
    "First Name": "Nombre",
    "Last Name": "Apellido",
    "Email": "Correo electrónico",
    "Enter Phone Number": "Ingresa número de teléfono",
    "Password": "Contraseña",
    "Confirm Password": "Confirmar contraseña",
    "Passwords do not match": "Las contraseñas no coinciden",
    "Signup": "Registrarse",
    "Sign up": "Registrarse",
    "Male": "Masculino",
    "Female": "Femenino",
    "All fields are required": "Todos los campos son obligatorios",
    "One uppercase": "Una mayúscula",
    "One number": "Un número",
    "Min 8 characters": "Mínimo 8 caracteres",
    "One special character": "Un carácter especial",

    //ModelLogin.js
    "Login": "Iniciar sesión",
    "Sign in": "Iniciar sesión",
    "Forget password": "Olvidé mi contraseña",
    "Add to cart": "Añadir al carrito",
    "kitchen note": "Nota de cocina",
    "Leave an addioonal note about your food (not too spicy)": "Deja una nota adicional sobre tu comida (no muy picante)",

    //Cart.js
    "Your Order": "Tu pedido",
    "Checkout": "Pagar",
    "tax-exclusive": "Excluido de impuestos",
    "ASAP": "lo antes posible",
    "By checking this box, you confirm your order to": "Al marcar esta casilla, confirmas tu pedido a",
    "This action is irreversible and commits to the purchase as outlined.": "Esta acción es irreversible y compromete la compra según lo descrito.",
    "Minimum order value": "Valor mínimo del pedido",
    " for ": " para ",
    "is ": "es ",

    //checkout.js
    "Contact": "Contacto",
    "Name": "Nombre",
    "Email": "Correo electrónico",
    "phone number": "número de teléfono",
    "Order in Advance": "Pedir con antelación",
    "Reset": "Restablecer",
    "Month D, YYYY HH:MM": "Mes D, AAAA HH:MM",
    "Payment Method": "Método de pago",
    "Cash": "Efectivo",
    "EPT": "TPE",
    "Credit/Debit Card": "Tarjeta de crédito/débito",
    "Place Your Order": "Realiza tu pedido",
    "Dine-in Fee": "Cargo por comer en el lugar",
    "Takeaway Fee": "Cargo por comida para llevar",
    "Delivery Fee": "Cargo por entrega",
    "Dine-in fees": "Cargos por comer en el lugar",
    "Takeaway fee": "Cargo por comida para llevar",
    "Delivery fee": "Cargo por entrega",
    "Delivery note": "Nota de entrega",
    "Leave a note for your delivery": "Deja una nota para tu entrega",
    "Your Addresses": "Tus direcciones",
    "Friend": "Amigo",
    "Home": "Casa",
    "Work": "Trabajo",
    "Other": "Otro",
    "Note for the delivery man:": "Nota para el repartidor",
    " Ex : Building or entry code": " Ej: Código del edificio o entrada",
    "Save": "Guardar",

    //modalresetPassword.js
    "Email has been send, check your email!": "¡El correo ha sido enviado, revisa tu correo!",
    "Resend Email": "Reenviar correo",

    //firstPage.js
    "All Stores": "Todas las tiendas",
    'Nearby Stores': "Tiendas cercanas",

    //ModelEmailSend.js
    "Email has been send, check your email!": "¡El correo ha sido enviado, revisa tu correo!",
    "Resend Email": "Reenviar correo",

    //AllStoresScreen.js
    "All Stores": "Todas las tiendas",

    //ModalModes.js
    "Select Your Mode": "Selecciona tu modo",
    "Yes": "Sí",
    "No": "No",

    //SelectStore.js
    "Select Store": "Seleccionar tienda",
    "Confirm": "Confirmar",
    "Delivery": "Entrega",
    "Takeaway": "Para llevar",
    "Dine-in": "Comer en el lugar",

    //ProductModel
    "leave your additional note": "deja tu nota adicional",
    "Additional note": "Nota adicional",

    //SubmitOrder.js
    "select at least": "selecciona al menos",
    "of": "de",
    "Quantity": "Cantidad",

    //OrdersHistory
    "TIME": "TIEMPO",
    "MODE": "MODO",
    "STATUS": "ESTADO",
    "PAYMENT": "PAGO",
    "TOTAL": "TOTAL",
    "VIEW": "VER",
    "accepted": "aceptado",
    "ready": "listo",
    "rejected": "rechazado",
    "missed": "perdido",
    "pending": "Pendiente",

    //OrderHistoryModal.js
    "(Without service fees)": "(Sin tarifas de servicio)",
    "ORDER ID": "ID DE PEDIDO",
    "Total price": "Precio total",
    "Add note": "Añadir nota",
    "Leave your note here": "Deja tu nota aquí",
    "Order Time": "Hora del pedido",
    "Schedule Order": "Programar pedido",
    "Minutes": "Minutos",
    "Seconds": "Segundos",
    "left.": "quedan.",
    "We regret to inform you that your order has been Missed": "Lamentamos informarte que tu pedido ha sido perdido",
    "We regret to inform you that your order has been Rejected": "Lamentamos informarte que tu pedido ha sido rechazado",
    "We are pleased to inform you that your order has been Accepted": "Nos complace informarte que tu pedido ha sido aceptado",
    "We are pleased to inform you that your order is Ready": "Nos complace informarte que tu pedido está listo",
    "Free": "Gratis",
    "By checking this box, you confirm that you are certain about proceeding with this specific order to": "Al marcar esta casilla, confirmas que estás seguro de proceder con este pedido específico a",
    "This action is irreversible and commits to the purchase as outlined. Please review your selections carefully before confirming": "Esta acción es irreversible y compromete la compra como se describe. Por favor, revisa cuidadosamente tus selecciones antes de confirmar",
    "The restaurant is closed, ASAP mode is not available": "El restaurante está cerrado, el modo ASAP no está disponible.",

    "Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.": "¿Antojo de algo delicioso? Ordena tus platos favoritos desde nuestra plataforma y disfruta de una entrega de comida rápida, fácil y confiable. Descubre una gran variedad de cocinas, ofertas exclusivas y pedidos sin complicaciones. Satisfacción garantizada con cada bocado.",
    "Get connected with us on social networks": "Conéctate con nosotros en redes sociales",
    "Pick your 1st choice": "Elige tu primera opción",
    "Previous choices": "Opciones anteriores",
    "Now pick your": "Ahora elige tu",
    "choice.": "opción.",
    "th": "°",
    "rd": "°",
    "nd": "°",
    "Chosen size": "Tamaño elegido",
    "Cart will be cleared , are you sure": "El carrito será vaciado, ¿estás seguro?",
    "From ": "De ",
    
    //new
    "Phone Number": "Número de teléfono",
    "Add Phone Number": "Añadir número de teléfono",
    "Enter your phone number": "Ingresa tu número de teléfono",
    "No payment method selected. Order creation aborted.": "No se ha seleccionado un método de pago. Creación del pedido abortada.",
    "Select Time Order": "Seleccionar hora del pedido",
    "Your order is waiting for the restaurant's approval.": "Tu pedido está esperando la aprobación del restaurante.",
    "Please correct the phone number.": "Corrige el número de teléfono.",
    "Unavailable": "No disponible",
    "Switching modes will clear your cart.": "Cambiar de modo vaciará tu carrito.",
    "Would you like to proceed?": "¿Te gustaría continuar?",
    "Enter your Delivery Address": "Ingresa tu dirección de entrega",
    "Address Type": "Tipo de dirección",
    "Continue": "Continuar",
    "empty": "vacío",
    "Coupon not found": "Cupón no encontrado",
    "The coupon has expired and is no longer valid": "El cupón ha expirado y ya no es válido",
    "Coupon is valid and active": "El cupón es válido y activo",
    "Internal Server Error": "Error interno del servidor",
    "Discount coupon": "Cupón de descuento",
    "Apply": "Aplicar",
    "Enter coupon": "Ingresar cupón",
    "Have a coupon?": "¿Tienes un cupón?",
    "The coupon has not started yet and is not valid": "El cupón aún no ha comenzado y no es válido",
}
