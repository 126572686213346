import { BaseURI } from "../..";
import { Executor } from "../../Executor";

export const  createOrder = (data) => {
  return Executor({
    method: "post",
    data,
    url: BaseURI + `/socket/orders`,
    isSilent: true,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getordershistory = (data) => {
  return Executor({
    method: "post",
    data,
    url: BaseURI + `/client/ordershistory`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const managefavorites = (data) => {
  return Executor({
    method: "put",
    data,
    url: BaseURI + `/client/managefavorites`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getfavoritesorders = (userId) => {
  return Executor({
    method: "get",
    url: BaseURI + `/client/getfavoritesorders/${userId}`,
    isSilent: false,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

export const getUberToken = (userId) => {
    return Executor({
        method: 'post',
        url: BaseURI + `/manager/getUberToken`,
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const createQuote = (data,token) => {
  return Executor({
    head : {
        authuber : token
    },
    data : data,
    method: "post",
    url: BaseURI + `/client/createquote`,
    isSilent: true,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};
export const getCoupon = (storeId,Coupon) => {
  return Executor({
    method: "get",
    url: BaseURI + `/client/validCoupon/${storeId}/${Coupon}`,
    isSilent: true,
    successFun: (data) => {},
    withErrorToast: false,
    withSuccessToast: false,
  });
};

