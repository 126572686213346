import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Modal from "react-modal";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./shared/index.js";
import { setOrderNotification } from "./shared/slice/order/OrderSlice.js";
// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
// import { StrictMode } from 'react';
Modal.setAppElement("#root");
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
    // console.log("Old service workers unregistered.");
  });
}
if ('serviceWorker' in navigator) {
navigator.serviceWorker.addEventListener('message', (event) => {
  if (event.data.type === 'ORDER_NOTIFICATION') {
  

      const aa = event.data.payload
    
      const bb = aa.orderInfo
  

     store.dispatch(setOrderNotification({ OrderNotification: bb }));
  }
});
}
// Register the Firebase service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
  
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <Provider store={store}>
    {/* <ClearCacheProvider duration={5000}> */}

    <PersistGate persistor={persistor}>
      {/* <React.StrictMode> */}
      {/* <StrictMode> */}

      <App />
      {/* </StrictMode> */}
      {/* </React.StrictMode> */}
    </PersistGate>
    {/* </ClearCacheProvider> */}
  </Provider>
);

reportWebVitals();

