import Modal from "react-modal";
import styled from "styled-components";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { store } from "../../../../../../shared";
import { setPromoToOldPromos } from "../../../../../../shared/slice/promos/PromosSlice";
import { setOrderToOldOrders } from "../../../../../../shared/slice/order/OrderSlice";
import { useTranslation } from "react-i18next";

export default function OrderHistoryModal({ order, setOrder }) {
    const { t } = useTranslation();

    const currency = useSelector((state) => state.restaurant.menu.currency);
    const reorder = async () => {
        // console.log("order.data", order.data);
        let newData = [];
        order.data.items.map((prod) => {
            let newObj = {
                price: prod?.price,
                note: prod?.note,
                quantity: prod?.quantity,
                product: {
                    description: prod?.description,
                    id: prod?.id,
                    name: prod?.name,
                    price: prod?.item_price,
                    size: prod?.size,
                    image: prod?.image,
                },
            };
            let options = [];
            prod.optionsGroup.map((option) => {
                let obj = {};
                option.options.map((opt) => {
                    obj = {
                        optionGroupeId: option?.optionGroupeId,
                        optionGroupeName: option?.optionGroupeName,
                        id: opt?.id,
                        name: opt?.name,
                        price_opt: opt?.price_opt,
                        quantity: opt?.quantity,
                        tax: opt?.tax,
                        options: opt?.options.map((o) => {
                            const { _id, ...rest } = o;
                            return rest;
                        }),
                    };
                });
                options.push(obj);
            });
            newObj.taxes = prod.tax.map((t) => {
                const { _id, ...rest } = t;
                return rest;
            });
            newObj.options = options;
            newData.push(newObj);
        });

        store.dispatch(setOrderToOldOrders({ order: newData }));
        let promos = [];
        newData = [];
        // console.log(order.data.promo);
        order.data.promo.map(async (promo) => {
            let newData = [];
            promo.items.map((prod) => {
                // console.log(prod);
                let newObj = {
                    price_after_discount: prod.subtotal,
                    price: prod.price,
                    note: prod?.note,
                    quantity: prod.quantity,
                    product: {
                        description: prod.description,
                        id: prod.id,
                        name: prod.name,
                        price: prod.item_price,
                        size: prod.size,
                        image: prod.image,
                    },
                };
                let options = [];
                prod.optionsGroup.map((option) => {
                    let obj = {};
                    option.options.map((opt) => {
                        obj = {
                            optionGroupeId: option.optionGroupeId,
                            optionGroupeName: option.optionGroupeName,
                            id: opt.id,
                            name: opt.name,
                            price_opt: opt.price_opt,
                            quantity: opt.quantity,
                            tax: opt.tax,
                            options: opt.options.map((o) => {
                                const { _id, ...rest } = o;
                                return rest;
                            }),
                        };
                    });
                    options.push(obj);
                });
                newObj.taxes = prod.tax.map((t) => {
                    const { _id, ...rest } = t;
                    return rest;
                });
                newObj.options = options;
                newData.push(newObj);
            });
            promos.push({ products: newData, promo: promo.promo });
        });
        store.dispatch(setPromoToOldPromos({ selectedPromos: promos }));
    };
    return (
        <StyledModalSignup
            isOpen={order.state}
            onRequestClose={() =>
                setOrder({
                    state: false,
                    data: null,
                })
            }
        >
            <OrderContainer>
                <div
                    style={{
                        marginLeft: 50,
                        color: "white",
                        fontFamily: "QuickSandLight",
                        fontSize: 12,
                        // textAlign: "end",
                    }}
                >
                    {t("ORDER ID")} :{" "}
                    {order?.data?._id.slice(
                        order?.data?._id.length - 4,
                        order?.data?._id.length
                    )}
                </div>
                <div
    style={{
        cursor: "pointer",
        marginRight: "1%",
    }}
    onClick={() => {
        setOrder({
            state: false,
            data: null,
        });
    }}
    role="button" // Change role from "pointer" to "button"
>
    <ClearIcon
        style={{ fontSize: 22, color: "white" }}
        tabIndex={0}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                setOrder({
                    state: false,
                    data: null,
                });
            }
        }}
    />
</div>
            </OrderContainer>

            <div style={{ padding: "20px 50px 50px 50px" }}>
                {order?.data?.promo.map((promo, i) => (
                    <div key={promo?.promo?._id}>
                        <div
                            style={{
                                // fontFamily: 'Roboto-BoldItalic',
                                fontSize: 20,
                                color: "#424242",
                                textAlign: "left",
                                marginBottom: "20px",
                            }}
                        >
                            {`${promo?.promo?.name.charAt(0).toUpperCase() +
                                promo?.promo?.name.slice(1)
                                }`}
                        </div>

                        {promo?.items?.map((item, itemIndex) => (
                            <React.Fragment key={itemIndex}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "QuickSandMedium",
                                            fontSize: 20,
                                            color: "#424242",
                                        }}
                                    >
                                        {`${item.quantity}x ${item?.name.charAt(0).toUpperCase() + item?.name.slice(1)
                                            } ${item?.size !== "S" ? `(${item.size})` : ""}`}
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        {/* {console.log(item)} */}
                                        <div
                                            style={{
                                                fontFamily: "QuickSandMedium",
                                                fontSize: 16,
                                                color: "#424242",
                                                // fontStyle: 'italic',
                                                textDecorationLine:
                                                    item.price_after_discount === item.item_price
                                                        ? "none"
                                                        : "line-through",
                                            }}
                                        >{`${item?.item_price} ${getSymbolFromCurrency(
                                            currency
                                        )}`}</div>

                                        {item?.price_after_discount !== item?.item_price && (
                                            <div
                                                style={{
                                                    marginLeft: 15,
                                                    fontFamily: "QuickSandMedium",
                                                    fontSize: 16,
                                                    color: "#424242",
                                                    // fontStyle: 'italic',
                                                }}
                                            >{`${item.price_after_discount === 0
                                                    ? t("Free")
                                                    : `${item.price_after_discount
                                                    } ${getSymbolFromCurrency(currency)}`
                                                }`}</div>
                                        )}
                                    </div>
                                </div>

                                {item?.optionsGroup.map((optionGroup) => (
                                    <React.Fragment key={optionGroup?.optionGroupeId}>
                                        <div
                                            style={{
                                                paddingLeft: 18,
                                                fontFamily: "QuickSandLight",
                                                fontSize: 16,
                                                color: "#7f7f7f",
                                            }}
                                        >
                                            {`${optionGroup?.optionGroupeName.charAt(0).toUpperCase() +
                                                optionGroup?.optionGroupeName.slice(1)
                                                }`}
                                            {optionGroup?.options.map((option, ind) => (
                                                <div
                                                    key={option._id}
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        // justifyContent: 'space-between',
                                                        // paddingLeft: 18,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontFamily: "QuickSand",
                                                                fontSize: 16,
                                                                color: "#424242",
                                                                // fontStyle: 'italic',
                                                            }}
                                                        >{`+${option?.quantity}x ${option?.name.charAt(0).toUpperCase() +
                                                            option?.name.slice(1)
                                                            }`}</div>
                                                        <div
                                                            style={{
                                                                fontFamily: "QuickSand",
                                                                fontSize: 16,
                                                                color: "#424242",
                                                                // fontStyle: 'italic',
                                                                marginLeft: 10,
                                                            }}
                                                        >{`(+${option.price_opt} ${getSymbolFromCurrency(
                                                            currency
                                                        )})`}</div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        {option?.options.map((opt) => (
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        paddingLeft: "36px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontFamily: "QuickSand",
                                                                            fontSize: 16,
                                                                            color: "#424242",
                                                                            // fontStyle: 'italic',
                                                                        }}
                                                                    >{`+${opt?.quantity}x ${opt?.name}`}</div>
                                                                    <div
                                                                        style={{
                                                                            fontFamily: "QuickSand",
                                                                            fontSize: 16,
                                                                            color: "#424242",
                                                                            // fontStyle: 'italic',
                                                                            marginLeft: 10,
                                                                        }}
                                                                    >
                                                                        (+{opt.price}{" "}
                                                                        {getSymbolFromCurrency(currency)})
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ))}
                                {item?.note && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            marginTop: "20px",
                                            marginBottom: "10px",
                                            width: "100%",
                                            backgroundColor: "#F7E4C6",
                                            padding: "10px",
                                            borderRadius: "10px",
                                            color: "#D2691E",
                                        }}
                                    >
                                        <div style={{ fontSize: "20px", marginRight: "5px" }}>
                                            🛈
                                        </div>
                                        <div style={{ fontSize: "13px" }}>Note: {item.note}</div>
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontFamily: "QuickSandBold",
                                        fontSize: 16,
                                        color: "#424242",
                                        marginBottom: 20,
                                        // fontStyle: "italic"
                                    }}
                                >
                                    {`Subtotal : ${item.subtotal} ${getSymbolFromCurrency(
                                        currency
                                    )}`}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                ))}
                {order.data.items.length > 0 && order.data.promo.length > 0 && (
                    <div
                        style={{
                            // fontFamily: 'Roboto-BoldItalic',
                            fontSize: 20,
                            color: "#424242",
                            textAlign: "left",
                            marginBottom: "20px",

                            // fontStyle: "italic"
                        }}
                    >{`Non-Promotional Products`}</div>
                )}

                {/* items */}
                {order.data.items.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "QuickSandMedium",
                                    fontSize: 20,
                                    color: "#424242",
                                }}
                            >{`${item.quantity}x ${item.name.charAt(0).toUpperCase() + item.name.slice(1)
                                } ${item.size !== "S" ? `(${item.size})` : ""}`}</div>
                            <div
                                style={{
                                    fontFamily: "QuickSandMedium",
                                    fontSize: 16,
                                    color: "#424242",
                                    // fontStyle: 'italic',
                                }}
                            >{`${item.item_price} ${getSymbolFromCurrency(currency)}`}</div>
                        </div>
                        {item.optionsGroup.map((optionGroup) => (
                            <React.Fragment key={optionGroup.optionGroupeId}>
                                <div
                                    style={{
                                        paddingLeft: 18,
                                        fontFamily: "QuickSandLight",
                                        fontSize: 16,
                                        color: "#7f7f7f",
                                    }}
                                >{`${optionGroup.optionGroupeName.charAt(0).toUpperCase() +
                                    optionGroup.optionGroupeName.slice(1)
                                    }`}</div>
                                <div>
                                    {optionGroup.options.map((option, ind) => (
                                        <div
                                            key={option._id}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                // justifyContent: 'space-between',
                                                paddingLeft: 18,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: "QuickSand",
                                                        fontSize: 16,
                                                        color: "#424242",
                                                        // fontStyle: 'italic',
                                                    }}
                                                >{`+${option.quantity}x ${option.name.charAt(0).toUpperCase() +
                                                    option.name.slice(1)
                                                    }`}</div>
                                                <div
                                                    style={{
                                                        fontFamily: "QuickSand",
                                                        fontSize: 16,
                                                        color: "#424242",
                                                        // fontStyle: 'italic',
                                                        marginLeft: 10,
                                                    }}
                                                >{`(+${option.price_opt} ${getSymbolFromCurrency(
                                                    currency
                                                )})`}</div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {option.options.map((opt) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            paddingLeft: "36px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontFamily: "QuickSand",
                                                                fontSize: 16,
                                                                color: "#424242",
                                                                // fontStyle: 'italic',
                                                            }}
                                                        >{`+${opt.quantity}x ${opt.name}`}</div>
                                                        <div
                                                            style={{
                                                                fontFamily: "QuickSand",
                                                                fontSize: 16,
                                                                color: "#424242",
                                                                // fontStyle: 'italic',
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            (+{opt.price} {getSymbolFromCurrency(currency)})
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        ))}
                        {item.note && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                    width: "100%",
                                    backgroundColor: "#F7E4C6",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    color: "#D2691E",
                                }}
                            >
                                <div style={{ fontSize: "20px", marginRight: "5px" }}>🛈</div>
                                <div style={{ fontSize: "13px" }}>Note: {item.note}</div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
                <div
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "flex",
                        // fontStyle: "italic"
                    }}
                >
                    <div></div>
                    <div
                        style={{
                            // fontFamily: 'QuickSand',
                            fontSize: 20,
                            color: "#424242",
                        }}
                    >{`${t("Total price")} : ${order.data.priceWithoutFee
                        } ${getSymbolFromCurrency(currency)}`}</div>
                </div>

                <div
                    style={{
                        fontSize: "13px",
                        fontFamily: "QuickSandMedium",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    {t("(Without service fees)")}
                </div>

                {/* <div style={{
                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "40px"
                }}>
                    <div onClick={() => reorder()} style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                        backgroundColor: "#444",
                        width: "100%",
                        height: "40px",
                        borderRadius: "10px",
                        cursor: "pointer",
                    }}>
                        <div style={{
                            color: "white",
                            fontSize: "17px",
                            fontFamily: "QuickSandMedium"
                        }}>
                            Add to cart
                        </div>
                    </div>
                </div> */}
            </div>
        </StyledModalSignup>
    );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
    return (
        <Modal className={modalClassName} portalClassName={className} {...props} />
    );
}

const StyledModalSignup = styled(ReactModalAdapter).attrs({
    modalClassName: "ModalSignup",
    overlayClassName: "OverlaySignup",
})`
  .ModalSignup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    // padding: 80px;
    border-radius: 15px;
    min-height: 300px;
    height: 600px;
    width: 600px;
    overflow-y: auto;
    // text-align: center;
    @media (max-width: 768px) {
      border-radius: 0px;
      z-index: 9999;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;
    }
  }
  .ModalSignup::-webkit-scrollbar {
    width: 0px;
  }
  .OverlaySignup {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;

const OrderContainer = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   position: absolute;
  background: #444;
  border-top-left-radius: 15px; /* Corrected property names */
  border-top-right-radius: 15px; /* Corrected property names */
  top: 0;
  width: 100%;
  height: 35px; /* Added "px" unit */
  @media (max-width: 768px) {
    border-top-left-radius: 0px; /* Corrected property name */
    border-top-right-radius: 0px; /* Corrected property name */
  }
`;
