import { createSlice } from "@reduxjs/toolkit";

export const AuthentificationInitialState = {
  loggedInUser: undefined,
  isLoggedIn: false,
  userId: undefined,
  token: undefined,
  loggedInGuest: undefined,
modalCheckout:false,
modelchekoutButton: false,
};

export const authentificationSlice = createSlice({
  name: "authentification",
  initialState: AuthentificationInitialState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.isLoggedIn = true;
      const user = action.payload.user;
      delete user.password;
      state.loggedInUser = user;
      state.userId = action.payload.user._id;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setLoggedInGuest: (state, action) => {
      state.isLoggedIn = true;
      const user = action.payload.user;
      
      state.loggedInGuest = user;
      state.userId = action.payload.user._id;

    },

    disconnect: (state) => {
    
      state.loggedInUser = undefined;
      state.isLoggedIn = false;
      state.userId = undefined;
      state.token = undefined;
      // guest mode
      state.loggedInGuest = undefined;
    },
    disconnects: (state) => {
      
      state.loggedInUser = undefined;
      state.isLoggedIn = false;
      state.loggedInGuest = undefined;
      state.loggedInGuest = false;
      state.userId = undefined;
      state.token = undefined;
    },
    setmodalCheckout:(state,action)=>{
      state.modalCheckout=action.payload.modalCheckout;
    },
    setmodelchekoutButton:(state,action)=>{
      state.modelchekoutButton=action.payload.modelchekoutButton;
    },
  },
});

export const {
  setLoggedInUser,
  setToken,
  setLoggedInGuest,
  disconnect,
  disconnects,
  setmodalCheckout,
  setmodelchekoutButton,
} = authentificationSlice.actions;
